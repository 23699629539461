<template>
  <header class="hero-teaser">
    <h1>
      <img src="../assets/logo.png" alt="Sascha und Stephan" class="logo" />
    </h1>
    <picture>
      <source srcset="../assets/hero-teaser-desktop.jpg" media="(min-width: 1100px)" />
      <source srcset="../assets/hero-teaser-desktop.jpg" media="(min-width: 768px)" />
      <source srcset="../assets/hero-teaser-mobile.jpg" media="(min-width: 0)" />
      <img src="../assets/hero-teaser-desktop.jpg" alt="" />
    </picture>
    <div class="date headline gold">
      <div class="slogan">Vielen Dank
        <div class="slogan-desktop">für den schönen Tag!</div>
      </div>
      <time class="slogan-mobile">Wir sind nun Ehemänner!</time>
    </div>
    <div class="arrow bounce">
      <img src="../assets/arrow.png" alt="" />
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeroTeaser',
  props: {
  }
}
</script>

<style scoped lang="scss">
.hero-teaser {
  display: flex;
  flex-direction: column;
  position: relative;
}

h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

img {
  display: block;
}

picture {
  display: block;
  height: 75vh;
  position: relative;

  @media screen and (min-width: 768px) {
    height: auto;
  }

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: bottom center;

    @media screen and (min-width: 768px) {
      position: relative;
      object-fit: contain;
    }
  }
}

.logo {
  width: 70vw;
  position: absolute;
  transform: translateY(-10vh);
  z-index: 1;

  @media screen and (min-width: 768px) {
    width: 30vw;
    transform: translateY(3vh);
  }

  @media screen and (min-width: 1100px) {
    width: 30vw;
    transform: translateY(7vh);
  }
}

.date {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  margin: 0;
  padding: 2vh 0;
  color: #d2ac7b;
  background: linear-gradient(#2b4333, #63a081);
  background-color: #d2ac7b;
  box-shadow: 0 0.5rem 0.5rem rgba(0,0,0,0.2);
  width: 100%;
  /*color: #d2ac7b;
  font-size: 2rem;
  line-height: 2.2rem;
  font-weight: bold;
  text-shadow: 0.25rem 0.25rem 0.5rem rgba(0,0,0,0.5), -0.25rem -0.25rem 0.5rem rgba(0,0,0,0.5);*/

  @media screen and (min-width: 768px) {
    position: relative;
  }

  body.dark & {
    background: linear-gradient(#000000, #333333);

    /*&:before {
      content: '';
      width: 100%;
      height: 0.5rem;
      background: #63a081;
      position: absolute;
      top: -0.75rem;
      box-shadow: 0 0.5rem 0.5rem rgba(0,0,0,0.5);
    }*/
  }

  .slogan {
    display: flex;
    color: white;

    @media screen and (max-width: 765px) {
      position: absolute;
      top: 8vh;
      font-size: 3rem;
      text-shadow: 0.25rem 0.25rem 0.5rem rgba(0,0,0,0.5), -0.25rem -0.25rem 0.5rem rgba(0,0,0,0.5);
    }

    .slogan-desktop {
      display: none;
      margin: 0   0.5rem;

      @media screen and (min-width: 768px) {
        display: block;
      }
    }
  }
  .slogan-mobile {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}

.subline {
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.arrow {
  position: absolute;
  width: 5rem;
  bottom: 12%;
  left: 50%;
  transform: translate(-50%, -150%);

  body.dark & {
    bottom: 14%;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }

  img {
    display: block;
    width: 100%;
  }
}

.bounce {
  animation: bounce 2s ease infinite;
}
@keyframes bounce {
    0% { transform:translate(-50%, 0%); }
    70% { transform:translate(-50%, 0%); }
    80% { transform:translate(-50%, -15%); }
    90% { transform:translate(-50%, 0%); }
    95% { transform:translate(-50%, -7%); }
    97% { transform:translate(-50%, 0%); }
    99% { transform:translate(-50%, -3%); }
    100% { transform:translate(-50%, 0); }
}
</style>
