<template>
    <HeroTeaser />
    <main>
      <div class="wrapper">
        <InfoCard
          class="hochzeitsbilder column"
          icon="camera"
          title="Hochzeitsbilder">
          <template v-slot:information>
            <p>Ihr seid ein unvergesslicher Teil dieser besonderen Feierlichkeit und wir möchten es euch ermöglichen, die Magie dieses Tages immer wieder zu erleben. Schaut euch dafür unsere Fotos an oder ladet sie herunter.</p>
            <router-link to="fotos" class="button">
              <span class="">Hochzeitsfotos ansehen</span>
              <img :src="getIconUrl('camera')" class="icon" alt="" />
            </router-link>
          </template>
          <template v-slot:asset>
            <picture>
              <source srcset="../assets/fotowall.jpg" media="(min-width: 1100px)" />
              <source srcset="../assets/fotowall.jpg" media="(min-width: 768px)" />
              <source srcset="../assets/fotowall.jpg" media="(min-width: 0)" />
              <img src="../assets/fotowall.jpg" alt="" />
            </picture>
          </template>
        </InfoCard>
        <InfoCard
          class="column"
          icon="baloons"
          title="Theater der Träume">
          <template v-slot:information>
            <p>Wiesenstraße 124</p>
            <p>40549 Düsseldorf</p>
          </template>
          <template v-slot:asset>
            <iframe id="googlemap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2498.6585933765286!2d6.704583115964317!3d51.2253646391056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8b5cee6c6b27b%3A0x6a8c2e9a55987614!2sTheater%20der%20Tr%C3%A4ume!5e0!3m2!1sde!2sde!4v1673029771333!5m2!1sde!2sde" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </template>
        </InfoCard>
        <!--InfoCard
          class="reversed"
          icon="hotel"
          title="Unterkünfte">
          <template v-slot:information>
            <div class="hotels-container">
              <HotelItem
                logo="novotel"
                name="Novotel Düsseldorf City West"
                street="Niederkasseler Lohweg 179"
                zip="40457"
                city="Düsseldorf"
                googleMaps="https://goo.gl/maps/gvVWNCPdhSFxwp27A"
                phone="0221 520 60 820"
                footnote="(1) Jeweils inkl. Frühstück"
                discount-code="Sascha & Stephan"
              >
              <template v-slot:prices>
                <ul>
                  <li>EZ: 84,00€ <sup>1</sup></li>
                  <li>DZ: 99,00€ <sup>1</sup></li>
                </ul>
              </template>
            </HotelItem>
              <HotelItem
                logo="bundb"
                name="B&B Hotel Neuss"
                street="Düsseldorfer Str. 246"
                zip="41460"
                city="Neuss"
                googleMaps="https://goo.gl/maps/pbBW9ZD5U9BE8pwLA"
                email="neuss@hotelbb.com"
                footnote="* Jeweils exklusive (2) oder inklusive (3) Frühstück"
                discount-code="Hochzeit Rath"
              >
              <template v-slot:prices>
                <ul>
                  <li>DZ: 64,00€ <sup>2</sup></li>
                  <li>DZ: 85,00€ <sup>3</sup></li>
                </ul>
              </template>
            </HotelItem>
              <p class="checkin-headline">Check-In ab 15 Uhr</p>
              <p class="checkin-text">Ein Early Check-In ist je nach Buchungslage voraussichtlich möglich und kann zusätzlich angefragt werden.</p>
            </div>
          </template>
          <template v-slot:asset>
            <picture>
              <source srcset="../assets/house.jpg" media="(min-width: 1100px)" />
              <source srcset="../assets/hero-teaser-desktop.jpg" media="(min-width: 768px)" />
              <source srcset="../assets/hero-teaser-desktop.jpg" media="(min-width: 0)" />
              <img src="../assets/hero-teaser-desktop.jpg" alt="" />
            </picture>
          </template>
        </InfoCard-->
      </div>
      <svg id="svg-home" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,128L120,149.3C240,171,480,213,720,240C960,267,1200,277,1320,282.7L1440,288L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
    </main>
    <FooterComponent></FooterComponent>
</template>


<script>
import HeroTeaser from '../components/HeroTeaser.vue'
import InfoCard from '../components/InfoCard.vue'
import FooterComponent from '../components/FooterComponent.vue'
export default {
    name: 'HomePage',
    scrollToTop: true,
    components: {
      HeroTeaser,
      InfoCard,
      FooterComponent,
    },
    methods: {
      getImgUrl(img) {
        const images = require.context('../assets/', false, /\.jpg$/)
        return images('./' + img + ".jpg")
      },
      getIconUrl(icon) {
        const images = require.context('../assets/', false, /\.svg$/)
        return images('./icon-' + icon + ".svg")
      }
    },
  }
</script> 

<style lang="scss">
.headline {
  text-shadow: 0.25rem 0.25rem 0.5rem rgba(0,0,0,0.5), -0.25rem -0.25rem 0.5rem rgba(0,0,0,0.5);
  font-size: 2rem;
  line-height: 2.2rem;
  font-weight: bold;
  
  &.gold {
    color: #d2ac7b;
  }

  &.green {
    color: #2b4333;

    body.dark & {
      color: black; 
    }
  }
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  min-height: 100vh;
}

.wrapper {
  width: 100%;

  @media screen and (min-width: 1100px) {
    max-width: 1100px;
    display: flex;
    flex-wrap: wrap;
  }
}

#svg-home {
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;

  @media screen and (max-width: 767px) {
    display: none;
  }

  path {
    fill: #2b4333;
  }
}

.checkin {
  &-headline {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  &-text {
    display: inline-block;
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

@media screen and (min-width: 768px) {
  .hotels-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .hotel-item {
      width: 50%;
    }
  }
}

.hochzeitsbilder {
  .button {
    margin-top: 1rem;
  }
}
</style>