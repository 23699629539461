<template>
  <footer>
    <p>Sascha Rath & Stephan Eiting</p>
    <p>Dorothea Habedank Straße 4</p>
    <p>40472 Düsseldorf</p>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  props: {
  }
}
</script>

<style lang="scss">
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 0;
  margin: 0;
  padding: 2vh 0;
  color: #2b4333;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: bold;
  background: linear-gradient(#bf7c37, #d2ac7b);
  background-color: #bf7c37;
  box-shadow: 0 0.5rem 0.5rem rgba(0,0,0,0.2);
  width: 100%;
  position: relative;

  body.dark & {
    color: black;

    /*&:before, &:after {
      content: '';
      width: 100%;
      height: 0.75rem;
      background: #2b4333;
      position: absolute;
      top: -1rem;
      box-shadow: 0 0.5rem 0.5rem rgba(0,0,0,0.2);
    }

    &:after {
      top: -1.5rem;
      background-color: #63a081;
      height: 0.2rem;
    }*/
  }

  p {
    margin-top: 0;
    margin-bottom: 0.25rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
