import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Gallery from '../views/Gallery.vue'

const routes = [
    {
      path: '/',
      name: 'HomePage',
      component: Home,
      meta: {
        title: 'Sascha und Stephan heiraten am 19. August 2023',
        metaTags: [
          {
            name: 'description',
            content: 'Sascha und Stephan heiraten am 19. August 2023'
          },
        ]
      }
    },
    {
      path: '/fotos',
      name: 'Hochzeitsbilder',
      component: Gallery,
      meta: {
        title: 'Bilder von Saschas und Stephans Hochzeit',
        metaTags: [
          {
            name: 'description',
            content: 'Bilder von Saschas und Stephans Hochzeit'
          },
        ]
      }
    },
    {
      path: '/upload',
      name: 'Upload - Hochzeitsbilder',
      component: () => {
        window.location.href = 'https://www.dropbox.com/request/QQZLSolyBw77MxccL2r3'
      },
      meta: {
        title: 'Hochzeitsbilder hochladen',
        metaTags: [
          {
            name: 'description',
            content: 'Upload der Hochzeitsbilder'
          },
        ]
      }
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`
  next()
})
  
export default router