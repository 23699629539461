<template>
  <section class="gallery-list">
    <strong>Erlebt unsere Hochzeit von Neuem und schaut euch unsere Hochzeitsbilder an!</strong>
    <p>Wir möchten die Freude und die wundervollen Erinnerungen unserer Hochzeit mit euch teilen und laden euch herzlich dazu ein, den wundervollen Tag Revue passieren zu lassen. Ihr seid ein unvergesslicher Teil dieser besonderen Feierlichkeit und wir möchten es euch ermöglichen, die Magie dieses Tages immer wieder zu erleben.</p>
    <div class="warning">
      <div class="box">
        <img :src="getIconUrl('error')" class="warning-icon" alt="" />
        <em>Geduldet euch noch ein wenig, bis wir alle Fotos bereitstellen können.</em>
      </div>
    </div>

    <strong>Hochzeitszeitung</strong>
    <p>Ihr habt keine Hochzeitszeitung erhalten, sie verlegt oder vergessen? Kein Problem! Klickt einfach auf den Button "Hochzeitszeitung herunterladen" und speichert die Zeitung auf eurem Smartphone, Tablet oder Computer, um sie euch digital immer wieder ansehen zu können.</p>
    <a href="/downloads/broschuere.pdf" download class="button-container">
      <span>
        <img :src="getImgUrl('hochzeitszeitung')" class="newspaper" alt="" />
      </span>
      <span class="button">
        <span class="">Hochzeitszeitung herunterladen</span>
        <img :src="getIconUrl('download')" class="icon" alt="" />
      </span>
    </a>
    <p>Vielen Dank dass ihr diesen besonderen Tag zu einem unvergesslichen Erlebnis gemacht habt.</p>



    <!--div class="row">
      <div class="column">
        <img src="/bilder/bild1.jpg" />
      </div>
      <div class="column">
        <img src="/bilder/bild1.jpg" />
      </div>
      <div class="column">
        <img src="/bilder/bild1.jpg" />
      </div>
      <div class="column">
        <img src="/bilder/bild2.jpg" />
      </div>
    </div-->
  </section>
</template>

<script>
export default {
  name: 'GalleryList',
  props: {
  },
  methods: {
    getImgUrl(img) {
      const images = require.context('../assets/', false, /\.jpg$/)
      return images('./' + img + ".jpg")
    },
    getIconUrl(icon) {
      const images = require.context('../assets/', false, /\.svg$/)
      return images('./icon-' + icon + ".svg")
    }
  },
}
</script>

<style scoped lang="scss">

  .gallery-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  h1 {
    font-size: 1.5rem;
    line-height: 2rem;
    color: #d2ac7b;
    padding: 0 1rem;

    @media screen and (min-width: 768px) {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }

  strong {
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #d2ac7b;
    padding: 0 1rem 1rem;
    margin-top: 2rem;

    @media screen and (min-width: 768px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  p {
    color: white;
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 1.5rem;
    margin-bottom: 3rem;
    padding: 0 2rem;

    @media screen and (min-width: 768px) {
      padding: 0 5rem;
    } 
  }

  .warning {
    opacity: 0.75;
    padding: 0 2rem;
    margin-bottom: 3rem;

    .box {
      display: flex;
      align-items: center;
      padding: 1rem;
      border: solid 1px #ffffff;
    }

    img {
      max-width: 50px;
    }

    em {
    font-size: 1rem;
    line-height: 1.5rem;
    color: #ffffff;
    margin-left: 0.5rem;

    @media screen and (min-width: 768px) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    // margin-top: 1rem;
    // margin-bottom: 1.5rem;
    .button {
      text-decoration: none;
    }
  }

  .newspaper {
    margin-bottom: 1rem;
    max-width: 200px;
    border: solid 1px rgba(255,255,255,0.2);
  }



  .row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
  }

  .column {
    flex: 100%;
    max-width: 100%;
    padding: 0 0.5rem;

    @media screen and (min-width: 768px) {
      flex: 50%;
      max-width: 50%;
    }

    @media screen and (min-width: 1100px) { 
      flex: 25%;
      max-width: 25%;
    }

    img {
      margin-top: 1rem;
      vertical-align: middle;
      width: 100%;
      object-fit: cover;
      aspect-ratio: 2 / 1;

      @media screen and (min-width: 768px) {
        aspect-ratio: 3 / 2;
      }

      @media screen and (min-width: 1100px) { 
        aspect-ratio: 1 / 1;
      }
    }
  }
</style>
