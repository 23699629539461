<template>
  <div class="info-card">
    <div class="asset">
      <slot name="asset"></slot>
    </div>
    <h2 class="title">
      <span class="headline gold">{{title}}
      </span>
    </h2>
    <div class="icon">
      <img :src="getImgUrl(icon)" :class="icon" alt="" />
    </div>
    <div class="information">
      <slot name="information"></slot>
    </div>
    <div class="link">
      <a href="" class="btn"></a>
    </div>
    <svg id="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,128L120,149.3C240,171,480,213,720,240C960,267,1200,277,1320,282.7L1440,288L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
</div>
</template>

<script>
export default {
  name: 'InfoCard',
  props: {
    title: String,
    icon: String,
  },
  methods: {
    getImgUrl(icon) {
      const images = require.context('../assets/', false, /\.png$/)
      return images('./icon-' + icon + ".png")
    }
  }
}
</script>

<style lang="scss">
.info-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #ffffff;
  background: linear-gradient(#2b4333, #63a081);
  padding-bottom: 2rem;

  body.dark & {
    background: linear-gradient(#000000, #333333);
    padding-bottom: 3rem;

    #svg {  
      transform: rotate(180deg);
      position: absolute;
      top: 30vh;
      z-index: 1;

      @media screen and (min-width: 768px) {
        display: none;
      }

      path {
        fill: #2b4333;
      }
    }

    &.reversed {
      background: linear-gradient(#333333 0%, #333333 50%, #000000 100%);

      #svg {
        transform: rotate(0deg);
        top: auto;
        bottom: 0;
      }
    }
  }

  &.reversed {
    background: linear-gradient(#63a081, #2b4333);
  }

  @media screen and (min-width: 1100px) {
    width: 100%;//50%;
    background: linear-gradient(#63a081, #2b4333);background: none;
    justify-content: flex-start;

    body.dark & {
      background: linear-gradient(#333333 0%, #333333 50%, #000000 100%);background: none;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      &.reversed {
        background: linear-gradient(#333333 0%, #333333 50%, #000000 100%);background: none;
      }
    }
  }
}

.asset {
  height: 30vh;
  width: 100%;
  order: 1;
  border-color: black;
  border-top-width: 0.25rem;
  border-bottom-width: 0.25rem;
  border-left: 0;
  border-right: 0;
  border-style: solid;

  @media screen and (min-width: 768px) {
    order: 2;
  }

  #googlemap, img {
    height: 100%;
    width: 100%;
  }

  img {
    object-fit: cover;
  }
}

.title {
  order: 3;
  margin-bottom: 2rem;
  z-index: 2;

  @media screen and (min-width: 768px) {
    order: 1;
  }

  @media screen and (min-width: 1100px) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin: 0;

    .headline {
      background: linear-gradient(#2b4333, #63a081);
      width: 100%;
      height: 100%;
      padding: 1.5rem 0.5rem;

      body.dark & {
        background: linear-gradient(#000000, #333333);background: none;
        margin-top: 5rem;
        height: auto;
      }
    }
  }
}

.icon {
  order: 2;
  height: 15vw;
  z-index: 3;

  img {
    width: 25vw;
    transform: translateY(-40%);

    &.hotel {
      width: 20vw;
    }
  }

  @media screen and (min-width: 768px) {
    order: 3;
    height: 10vw;

    img {
      width: 15vw;

      &.hotel {
        width: 10vw;
      }
    }
  }

  @media screen and (min-width: 1100px) {

    img {
      width: 10vw;

      &.hotel {
        width: 8vw;
      }
    }
  }
}

.information {
  order: 4;
  font-size: 1.5rem;
  line-height: 1.8rem;
  max-width: 75%;
  z-index: 2;

  @media screen and (min-width: 1100px) {
    display: flex;
  }

  .column & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  p {
    margin: 0 0 1rem;
  }
}

.link {
  order: 5;
}
</style>
