<template>
    <GalleryHeroTeaser />
    <main>
      <div class="wrapper">
        <GalleryList />
        <p class="back-homepage">
          <router-link to="/" class="button button--ghost">
            <img :src="getIconUrl('undo')" class="icon" alt="" />
            <span>Zurück zur Startseite</span>
          </router-link></p>
      </div>
      <svg id="svg-home" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,128L120,149.3C240,171,480,213,720,240C960,267,1200,277,1320,282.7L1440,288L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
    </main>
    <FooterComponent></FooterComponent>
</template>


<script>
import GalleryHeroTeaser from '../components/GalleryHeroTeaser.vue'
import FooterComponent from '../components/FooterComponent.vue'
import GalleryList from '../components/GalleryList.vue'
export default {
    name: 'HomePage',
    scrollToTop: true,
    components: {
      GalleryHeroTeaser,
      FooterComponent,
      GalleryList,
    },
    methods: {
    getIconUrl(icon) {
      const images = require.context('../assets/', false, /\.svg$/)
      return images('./icon-' + icon + ".svg")
    }
  },
  }
</script> 

<style lang="scss">
.headline {
  text-shadow: 0.25rem 0.25rem 0.5rem rgba(0,0,0,0.5), -0.25rem -0.25rem 0.5rem rgba(0,0,0,0.5);
  font-size: 2rem;
  line-height: 2.2rem;
  font-weight: bold;
  
  &.gold {
    color: #d2ac7b;
  }

  &.green {
    color: #2b4333;

    body.dark & {
      color: black; 
    }
  }
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  min-height: 100vh;
}

.wrapper {
  width: 100%;

  @media screen and (min-width: 1100px) {
    max-width: 1100px;
    display: flex;
    flex-wrap: wrap;
    z-index: 2;
  }
}

#svg-home {
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;

  @media screen and (max-width: 767px) {
    display: none;
  }

  path {
    fill: #2b4333;
  }
}

.checkin {
  &-headline {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  &-text {
    display: inline-block;
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

@media screen and (min-width: 768px) {
  .hotels-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .hotel-item {
      width: 50%;
    }
  }
}

.back-homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 5rem;
  width: 100%;

  @media screen and (min-width: 768px) {
    margin-bottom: 15rem;
  }
}
</style>