<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
@import './assets/scss/global.scss';
#app {
  display: flex;
  flex-direction: column;
  background: linear-gradient(#2b4333, #63a081);
  background-color: #2b4333;
  min-height: 100vh;

  body.dark & {    
    background: linear-gradient(#000000 0%, #000000 50%, #333333 100%);
  }
}
</style>
